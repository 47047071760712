export default [
    {
        path: '/role/and/permission',
        name: 'role-permission',
        component: () => import('@/views/roleAndPermission/permission'),
        meta: {
            action:'read',
            resource:'Permission',
            pageTitle: 'Role and Permission',
            breadcrumb: [
                {
                    text: 'Role and Permission',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/and/permission',
        name: 'user-permission',
        component: () => import('@/views/roleAndPermission/userPermission'),
        meta: {
            action:'read',
            resource:'Permission',
            pageTitle: 'User and Permission',
            breadcrumb: [
                {
                    text: 'User and Permission',
                    active: true,
                },
            ],
        },
    },

]
