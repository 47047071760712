import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=7b4188f4"
import script from "./Event.vue?vue&type=script&lang=js"
export * from "./Event.vue?vue&type=script&lang=js"
import style0 from "./Event.vue?vue&type=style&index=0&id=7b4188f4&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\Users\\user\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports